import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from '../../../../core/classes/base-api';
import { GenerateQrRequest } from '../../../models/api/generate-qr-request.model';
import { qrApi } from '../../../../backend/qr.api';
import { ResolveQrRequest } from '../../../models/api/resolve-qr-request.model';
import { ProductDisplayResponse } from '../../../models/api/product-display-response.model';
import { GeoLocationRequest } from '../../../models/api/geo-location-request.model';
import { GeoNamesResponse } from '../../../models/api/geo-names-response.model';
import { GetGeneratedFilesResponse } from '../../../models/api/get-generated-files-response.model';

@Injectable({
    providedIn: 'root',
})
export class QrApiService extends BaseApi {
    private _baseUrl = this.getBaseUrl();

    constructor(
        private readonly _http: HttpClient,
    ) {
        super();
    }

    postQrResolve(resolveQrRequest: ResolveQrRequest): Observable<ProductDisplayResponse> {
        return this._http.post<ProductDisplayResponse>(`${this._baseUrl}${qrApi.postQrResolve}`, resolveQrRequest);
    }

    postQrGeo(geoLocationRequest: GeoLocationRequest): Observable<GeoNamesResponse> {
        return this._http.post<GeoNamesResponse>(`${this._baseUrl}${qrApi.postQrGeo}`, geoLocationRequest);
    }

    postQrGenerate(generateQrRequest: GenerateQrRequest): Observable<void> {
        return this._http.post<void>(`${this._baseUrl}${qrApi.postQrGenerate}`, generateQrRequest);
    }

    getQrGeneratedFiles(productDetailUuid: string): Observable<GetGeneratedFilesResponse> {
        const url = qrApi.getQrGeneratedFiles.replace('{productDetailUuid}', productDetailUuid);
        return this._http.get<GetGeneratedFilesResponse>(`${this._baseUrl}${url}`);
    }

    getQrDownload(uuid: string): Observable<HttpResponse<Blob>> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response' as 'body',
            responseType: 'blob' as 'json'
        };
        const url = qrApi.getQrDownload.replace('{uuid}', uuid);
        return this._http.get<HttpResponse<Blob>>(`${this._baseUrl}${url}`, httpOptions);
    }
}
